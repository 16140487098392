import _formatDistance from "./en-US/_lib/formatDistance.js";
import _formatLong from "./en-US/_lib/formatLong.js";
import _formatRelative from "./en-US/_lib/formatRelative.js";
import _localize from "./en-US/_lib/localize.js";
import _match from "./en-US/_lib/match.js";
var exports = {};
exports.enUS = void 0;
var _index = _formatDistance;
var _index2 = _formatLong;
var _index3 = _formatRelative;
var _index4 = _localize;
var _index5 = _match;

/**
 * @category Locales
 * @summary English locale (United States).
 * @language English
 * @iso-639-2 eng
 * @author Sasha Koss [@kossnocorp](https://github.com/kossnocorp)
 * @author Lesha Koss [@leshakoss](https://github.com/leshakoss)
 */
const enUS = exports.enUS = {
  code: "en-US",
  formatDistance: _index.formatDistance,
  formatLong: _index2.formatLong,
  formatRelative: _index3.formatRelative,
  localize: _index4.localize,
  match: _index5.match,
  options: {
    weekStartsOn: 0 /* Sunday */,
    firstWeekContainsDate: 1
  }
};
export default exports;
const _enUS = exports.enUS;
export { _enUS as enUS };